/**
 * Async form
 *
 */
window.form = function()
{
    var self                    = this;
    var $form                   = $('.js-form');
    var $errors                 = $('.js-errors');
    var $recaptcha              = $('.js-recaptcha');
    var $loader                 = $('.js-loader');
    var $form_button            = $('.js-form-button');
    var $form_button_day_ticket = $('.js-day-ticket-form-button');
    var $current_form = null;

    self.init = function()
    {
        $form.on('submit', self.submitForm);
        $form.find('select, input').on('keyup keydown change', self.showRecaptcha);
    };

    self.submitForm = function(e)
    {
        e.preventDefault();
        var form_data = new FormData( $(this)[0] );
        $current_form = $(this);
        $('.js-error').text('');
        $errors.addClass('hide');
        $loader.addClass('is-active');

        $form_button_day_ticket.prop('disabled', true);

        $form_button.addClass('hide');
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: form_data,
            cache: false,
            contentType: false,
            processData: false,
            async: true,
            success: self.formSuccess,
            error: self.formError
        });
    };

    self.formSuccess = function(data)
    {
        if (data.redirect_url) {
            window.location.href = data.redirect_url;
        } else {
            $current_form.parent().find('.js-success').html(data.success).removeClass('hide');
            $loader.removeClass('is-active');

            // for hidden success messages
            if($current_form.parent().find('.js-success').hasClass('hide')) {
             $current_form.parent().find('.js-success').removeClass('hide');
            } else {
             $current_form.parent().find('.js-success').removeClass('hide');
            }

            $current_form.remove();
        }

    };

    self.formError = function(error)
    {
        $form_button.removeClass('hide');
        $loader.removeClass('is-active');
        $form_button_day_ticket.prop('disabled', false);
        let errors = [];

        if (error.responseJSON) {
            errors = error.responseJSON;
        }
        if ($recaptcha[0]) {
            grecaptcha.reset();
        }
        var error_set = false;
        // loop through every error
        if(errors != 'undefined' && errors.length !== 0){
            if ($form_button_day_ticket.length) {
                $('html,body').animate( { scrollTop: 0 }, 1000 );
            }
            for (let error in errors) {
                // loop through every array element in error
                for (var i = 0; i < errors[error].length; i++) {
                    // Check if an recaptch error is found
                    if (errors.recaptcha != null) {
                        // Find the recaptcha elements and show the error.
                        if ($current_form.children().hasClass('js-captcha')) {
                            error_set = true;
                            $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                        }
                    } else {
                        error_set = true;
                        // If no recaptcha error is found, show the other errors.
                        $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                    }
                    $errors.removeClass('hide');
                }
            }
        }

        // If no errror message was set return global error.
        if(error_set == false){
            $errors.find('[data-error-name="global_error_message"]').text(Lang.get('frontend.js.global.error'));
        }
    };

    /**
     * Show the recaptcha
     * @author Bas Lokerman <bas@click.nl>
     * @return void
     */
    self.showRecaptcha = function()
    {
        $recaptcha.removeClass('hide');
    };
}
